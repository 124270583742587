<template>
	<div class="row">
		<div class="col-12">
			<div class="card mb-2">
				<div class="card-body p-3">
					<div class="row">
						<div class="col-12">
							<h6 class="mb-3">👍 {{ $t("previewMessage.custom.title") }}</h6>
						</div>
						<div class="col-12 mb-3">
							<TextAreaDeck :model="form.message" position_emoji="top" @attValor="attValor($event, 'message')" :label="`<i class='far fa-comment-alt color-theme font-13 mr-1'></i> ${$t('previewMessage.message')}`" />
						</div>
						<div class="col-6 d-flex">
							<label class="mr-2">{{ $t("previewMessage.buttons.image") }}:</label>
							<label class="switch">
								<input type="checkbox" v-model="form.usarImage">
								<span class="slider"></span>
							</label>
						</div>
						<div class="col-6 d-flex">
							<label class="mr-2">{{ $t("previewMessage.buttons.embed") }}:</label>
							<label class="switch">
								<input type="checkbox" v-model="form.usarEmbed">
								<span class="slider"></span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card mb-2" v-if="form.usarImage">
				<div class="card-body p-3">
					<div class="row">
						<div class="col-12">
							<h6 class="mb-3">😘 {{ $t("previewMessage.image.title") }}</h6>
						</div>
						<div class="col-12">
							<ul class="nav nav-tabs3 white">
								<li class="nav-item"><a class="nav-link show active" data-toggle="tab" href="#Customize">{{ $t("previewMessage.image.menu.custom.titleMenu") }}</a></li>
								<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Select">{{ $t("previewMessage.image.menu.select") }}</a></li>
								<li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Upload">{{ $t("previewMessage.image.menu.upload") }}</a></li>
							</ul>
							<div class="tab-content">
								<div class="tab-pane show active" id="Customize">
									<div class="row">
										<div class="col-12 mb-3">
											<label><i class="far fa-heading color-theme font-13 mr-1"></i> {{ $t("previewMessage.image.menu.custom.titleText") }}</label>
											<input type="text" class="form-control" maxlength="200" v-model="form.imageTitle">
										</div>
										<div class="col-12 mb-3">
											<label><i class="far fa-h2 color-theme font-13 mr-1"></i> {{ $t("previewMessage.image.menu.custom.subtitleText") }}</label>
											<input type="text" class="form-control" maxlength="200" v-model="form.imageSubtitle">
										</div>
										<div class="col-xl-6 mb-3">
											<label><i class="far fa-palette color-theme font-13 mr-1"></i> {{ $t("previewMessage.image.menu.custom.textColor") }}</label>
											<verte menuPosition="top" v-model="form.imageColorText" class="w-100">
												<div :style="'background-color:' + form.imageColorText + ';'"></div>
											</verte>
										</div>
										<div class="col-xl-6 mb-3">
											<label><i class="far fa-font color-theme font-13 mr-1"></i> {{ $t("previewMessage.image.menu.custom.select") }}</label>
											<v-select title="Desktop" :options="['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Impact', 'Palatino', 'Symbol', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings']" v-model="form.imageFont" />
											<select title="Mobile" class="form-control" v-model="form.imageFont">
												<option v-for="(option, index) in ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Impact', 'Palatino', 'Symbol', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings']" :key="index" :value="option">{{ option }}</option>
											</select>
										</div>
										<div class="col-xl-6 mb-3 mb-xl-0">
											<label><i class="far fa-expand-alt color-theme font-13 mr-1"></i> {{ $t("previewMessage.image.menu.custom.border") }}</label>
											<input type="range" class="w-100" min="0" max="10" v-model="form.imageBorderSize">
										</div>
										<div class="col-xl-6">
											<label><i class="far fa-text-size color-theme font-13 mr-1"></i> {{ $t("previewMessage.image.menu.custom.font") }}</label>
											<input type="range" class="w-100" min="12" max="16" v-model="form.imageFontSize">
										</div>
									</div>
								</div>
								<div class="tab-pane" id="Select">
									<div class="row row-cols-2 row-cols-xl-4">
										<img src="@/assets/images/capas/1.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/2.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/3.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/4.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/5.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/6.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/7.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/8.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/9.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/10.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/11.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/12.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/13.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/14.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/15.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/16.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/17.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/18.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/19.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/20.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/21.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/22.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/23.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/24.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/25.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/26.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/27.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/28.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/29.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/30.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/31.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/32.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/33.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/34.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/35.png" class="cursor-pointer mb-2" @click="setarImg" />
										<img src="@/assets/images/capas/36.png" class="cursor-pointer mb-2" @click="setarImg" />
									</div>
								</div>
								<div class="tab-pane" id="Upload">
									<div class="w-100 text-center my-5" v-if="serverS.donatePack == 'Free'">
										<span class="d-block mb-1">{{ $t("vipMessage") }}</span>
										<span class="d-block mb-4"><i class="far fa-star color-theme font-13 mr-2"></i>Basic</span>
										<router-link to="/Premium" data-dismiss="modal" class="btn btn-primary btn-lg font-16"><i class="far fa-long-arrow-right font-14 mr-3"></i>{{ $t("premium.seePlans") }}</router-link>
									</div>
									<UploadImageDeck v-else @attValor="attValor($event, 'image')" identificador="image" width="100%" :minAspectRatio="22 / 9" :maxAspectRatio="22 / 9" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card mb-2" v-if="form.usarEmbed">
				<div class="card-body p-3">
					<div class="row">
						<div class="col-12">
							<h6 class="mb-3">😘 {{ $t("previewMessage.embedMessage.title") }}</h6>
						</div>
						<div class="col-12 mb-2">
							<div class="form-group c_form_group mb-0 p-1">
								<div class="row">
									<div class="col p-2">
										<div class="row ">
											<label class="col font-15"><i class="far fa-palette color-theme font-13 mr-1 "></i> {{ $t("previewMessage.embedMessage.color") }}</label>
											<div class="col-1">
												<label class="switch">
													<input type="checkbox" @click="showBtnStripe = !showBtnStripe" v-model="showBtnStripe">
													<span class="slider"></span>
												</label>
											</div>
										</div>
										<verte menuPosition="top" v-model="form.embedColor" :enableAlpha="false" class="w-100">
											<div :style="'background-color:' + form.embedColor + ';'"></div>
										</verte>
									</div>
									
									<div class="w-max-content px-2" v-if="showBtnStripe">
										<UploadImageDeck @attValor="attValor($event, 'embedThumbnail')" :model="form.embedThumbnail" width="80px" height="80px" identificador="embedThumbnail" :minAspectRatio="4 / 5" :maxAspectRatio="4 / 5"></UploadImageDeck>
									</div>
									<div class="w-max-content px-2" v-else>
										<img class="rounded object-fit-cover" width="80px" height="80px" src="@/assets/images/dark.jpg">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 mb-2">
							<div class="form-group c_form_group mb-0 p-1">
								<div class="row">
									<div class="col">
										<div class="row">
											<InputDeck class="col" :model="form.embedAuthor" @attValor="attValor($event, 'embedAuthor')" :label='`<i class="far fa-user color-theme font-13 mr-1"></i> ${$t("previewMessage.embedMessage.author")}`'/>
											<div class="col-1">
												<label class="switch">
													<input type="checkbox"  @click="showBtnAuthor = !showBtnAuthor" v-model="showBtnAuthor">
													<span class="slider"></span>
												</label>
											</div>
										</div>

										
									</div>
									
									<div class="w-max-content px-2" v-if="showBtnAuthor">
										<UploadImageDeck @attValor="attValor($event, 'embedAuthorImage')" :model="form.embedAuthorImage" width="80px" height="80px" identificador="embedAuthorImage" :minAspectRatio="4 / 4" :maxAspectRatio="4 / 4"></UploadImageDeck>
									</div>
									<div class="w-max-content px-2" v-else>
										<img class="rounded object-fit-cover" width="80px" height="80px" src="@/assets/images/dark.jpg">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 mb-2">
							<InputDeck :model="form.embedTitle" @attValor="attValor($event, 'embedTitle')" :label="`<i class='far fa-heading color-theme font-13 mr-1'></i> ${$t('previewMessage.embedMessage.titleEmbed')}`"/>
						</div>
						<div class="col-12 mb-2">
							<TextAreaDeck :model="form.embedMessage" position_emoji="top" @attValor="attValor($event, 'embedMessage')" :label="`<i class='far fa-comment-alt color-theme font-13 mr-1'></i> ${$t('previewMessage.embedMessage.message')}`" />
						</div>
						<div class="col-12 mb-2">
							<div class="form-group c_form_group mb-0">
								<div class="row">
									<div class="col-6 mb-2 align-self-center">
										<label class="font-15 mb-0"><i class="far fa-align-left color-theme font-13 mr-1"></i> {{ $t("previewMessage.embedMessage.fields.title") }}</label> 
									</div>
									<div class="col-6 mb-2 text-right">
										<button class="btn btn-default btn-sm font-13" @click="addField"><i class="far fa-plus font-12 mr-1"></i>{{ $t("previewMessage.embedMessage.fields.btn") }}</button>
									</div>
									<div class="col-12">
										<div class="form-group c_form_group mb-1" v-for="(field, index) in form.fields" :key="index">
											<div class="row">
												<div class="col-6 align-self-center"><i class="far fa-comment-alt color-theme font-13 mr-1"></i>  {{ $t("previewMessage.embedMessage.fields.field.title")+" "+(index + 1)}}</div>
												<div class="col-6 text-right">
													<button class="btn btn-danger btn-sm font-13" @click="removeField(index)"><i class="fal fa-trash font-12 mr-1"></i> {{ $t("previewMessage.embedMessage.fields.remove") }}</button>
												</div>
												<div class="col-12 mb-1">
													<InputDeck :model="field.title" @attValor="attValorField($event, 'title', field.id)" :label='`<i class="far fa-heading color-theme font-13 mr-1"></i> ${$t("previewMessage.embedMessage.fields.field.subTitle")}`'/>
												</div>
												<div class="col-12">
													<InputDeck :model="field.value" @attValor="attValorField($event, 'value', field.id)" :label='`<i class="far fa-h2 color-theme font-13 mr-1"></i> ${$t("previewMessage.embedMessage.fields.field.value")}`' />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 mb-2">
							<div class="form-group c_form_group mb-0 p-1">
								<div class="row">
									<div class="col">
										<div class="row">
											<InputDeck class="col" :model="form.embedFooter" @attValor="attValor($event, 'embedFooter')" :label='`<i class="far fa-h4 color-theme font-13 mr-1"></i> ${$t("previewMessage.embedMessage.footer")}`' />
											<div class="col-1">
												<label class="switch">
													<input type="checkbox"  @click="showBtnFooter = !showBtnFooter" v-model="showBtnFooter">
													<span class="slider"></span>
												</label>
											</div>
										</div>
									</div>
									
									<div class="w-max-content px-2" v-if="showBtnFooter">
										<UploadImageDeck @attValor="attValor($event, 'embedFooterImage')" :model="form.embedFooterImage" width="80px" height="80px" identificador="embed_author_image" :minAspectRatio="4 / 4" :maxAspectRatio="4 / 4"></UploadImageDeck>
									</div>
									<div class="w-max-content px-2" v-else>
										<img class="rounded object-fit-cover" width="80px" height="80px" src="@/assets/images/dark.jpg">
									</div>
								</div>
							</div>
						</div>
						<div class="col-12">
							<div class="d-flex">
								<label class="mr-2"><i class="far fa-image color-theme font-13 mr-1"></i> {{$t("previewMessage.embedMessage.image")}}</label>
									<label class="switch">
									<input type="checkbox"  @click="showBtnMessage = !showBtnMessage" v-model="showBtnMessage">
									<span class="slider"></span>
								</label>
							</div>
							<UploadImageDeck @attValor="attValor($event, 'embedImage')" v-if='showBtnMessage' :model="form.embedImage" width="100%" identificador="embedImage" :minAspectRatio="4 / 4" :maxAspectRatio="4 / 4"></UploadImageDeck>
							<img v-else class="rounded object-fit-cover" width="609px" height="46px" src="@/assets/images/dark.jpg">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

//	npm install verte --save
// https://baianat.github.io/verte/guide/getting-started.html

import $ from 'jquery'
import { mapState } from 'vuex';
import InputDeck from '@/components/InputDeck.vue'
import TextAreaDeck from '@/components/TextAreaDeck.vue'
import UploadImageDeck from '@/components/UploadImageDeck.vue'

export default {
	name: 'MessageDeck',
	props: ['message'],
	components: {
		TextAreaDeck, InputDeck, UploadImageDeck
	},
	data: function () {
		return {
			form: {},
			showBtnStripe: true, showBtnAuthor: true, showBtnFooter: true, showBtnMessage: true
		}
	},
	watch: {
		'showBtnStripe' : function () {
			if(this.showBtnStripe == false) {
				this.form.embedThumbnail = null
			}
		},
		'showBtnAuthor' : function () {
			if(this.showBtnAuthor == false) {
				this.form.embedAuthorImage = null
			}
		},
		'showBtnFooter' : function () {
			if(this.showBtnFooter == false) {
				this.form.embedFooterImage = null
			}
		},
		'showBtnMessage' : function () {
			if(this.showBtnMessage == false) {
				this.form.embedImage = null
			}
		},
		'form.embedThumbnail' : function (value) {
			if (value) this.attValor(value, 'embedThumbnail')
		},
		'form.usarImage' : function (value) {
			if (value) this.attValor(value, 'usarImage')
		},
		'form.usarEmbed' : function (value) {
			if (value) this.attValor(value, 'usarEmbed')
		},
		'form.imageTitle' : function (value) {
			if (value) this.attValor(value, 'imageTitle')
		},
		'form.imageSubtitle' : function (value) {
			if (value) this.attValor(value, 'imageSubtitle')
		},
		'form.imageColorText' : function (value) {
			if (value) this.attValor(value, 'imageColorText')
		},
		'form.imageBorderSize' : function (value) {
			if (value) this.attValor(value, 'imageBorderSize')
		},
		'form.imageFont' : function (value) {
			if (value) this.attValor(value, 'imageFont')
		},
		'form.imageFontSize' : function (value) {
			if (value) this.attValor(value, 'imageFontSize')
		},
		'form.embedCssStripe' : function (value) {
			if (value) this.attValor(value, 'embedCssStripe')
		},
		'form.embedColor' : function (value) {
			if (value) this.attValor(value, 'embedColor')
		}
	},
	computed: {
		...mapState({
			serverS: state => state.serverS,
			clientId: state => state.clientId,
			redirectUri: state => state.redirectUri,
			urlRest: state => state.urlRest,
		})
	},
	methods: {
		attValor: function (e, valor) {
			if (!e) return;

			this.form[valor] = e;
			this.$emit("attObjetoMessage", this.form)
		},
		attValorField: function (e, campo, id ) {
			for(var i in this.form.fields){
				if(this.form.fields[i].id == id ){
					this.form.fields[i][campo] = e
				}
			}
		},
		setarImg: function (e) {
			let img_temp = e.target
			this.form.image = img_temp.src
		},
		addField: function() {
			this.form.qtd_fields_count = this.form.qtd_fields_count + 1
			this.form.fields.push({ id: this.form.qtd_fields_count ,title: "Title Text", value: "Value text"})
			this.attValor(this.form.fields, 'fields')
		},
		removeField: function(index) {
			this.form.fields.splice(index, 1);
			this.attValor(this.form.fields, 'fields')
		}
	},
	created() {
		this.form = this.message;
		if(this.form.embedThumbnail == null) {
			this.showBtnStripe = false
		}
		if(this.form.embedAuthorImage == null) {
			this.showBtnAuthor = false
		}
		if(this.form.embedFooterImage == null) {
			this.showBtnFooter = false
		}
		if(this.form.embedImage == null) {
			this.showBtnMessage = false
		}
		
	},
	mounted() {
		$(".tab_deck").tab()
	}
}

</script>