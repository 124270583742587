<template>
	<div>
		<div class="image_deck cursor-pointer" :style="'width: '+ width +'; height: '+ height +';'" @click="abrirModalEnviarFoto">
			<img :src="image == null ? '' : image" v-if="image != null" class="w-100 h-100" @error="imageError" />
			<div v-else style="font-size: 28px; text-align: center; font-weight: bold;" class="h-100 d-flex align-items-center justify-content-center">+</div>
		</div>

		<div class="modal fade" :id="identificador" tabindex="-1" data-backdrop="static" aria-labelledby="modalEditarFotoLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
				<div class="modal-content h-100">
					<div class="modal-header">
						<h5 class="modal-title h4" id="modalEditarFotoLabel">Upload</h5>
						<button type="button" class="close" @click="fechar" aria-label="Close"><span aria-hidden="true">×</span></button>
					</div>
					<div class="modal-body">
						<div class="card">
							<div class="card-body p-3">
								<div class="row m-0">
									<div class="col-12 px-1 mb-3" v-if="!String(image).startsWith('data:image/jpeg;base64')">
										<label><i class="far fa-link color-theme font-13 mr-1"></i> URL</label>
										<input type="text" class="form-control" v-model="image" />
									</div>
									<div class="col-12 px-1">
										<div class="row">
											<div :class="imageTemporary != null ? 'col-xl-6' : 'col-12'">
												<div class="form-group file-area">
													<label for="images" class="font-15"><i class="fa fa-image color-theme font-13 mr-1"></i> {{ $t("previewMessage.uploadImage.up") }}</label>
													<input type="file" class="dropify" name="images" id="images" ref="images" accept=".jpg,.jpeg,.png,.webp" required="required" @change="onFileChange" @drop="onFileChange" @click="onFileChange" />
													<div class="file-dummy">
														<div class="default">.jpg | .png</div>
													</div>
												</div>
											</div>

											<div class="col-xl-6 mx-auto pt-3 mt-1 position-relative" v-if="imageTemporary != null">
												<div class="cropper-box">
													<cropper class="cropper" :src="imageTemporary" :stencil-props="AspectRatio" :auto-zoom="true" :backgroundClass="'bg-white'" @change="change" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-round btn-primary" @click="salvarFoto">{{ $t("previewMessage.uploadImage.buttons.save") }}</button>
						<button type="button" class="btn btn-round btn-danger" @click="fechar">{{ $t("previewMessage.uploadImage.buttons.cancel") }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'

export default {
	name: 'UploadImageDeck',
	props: ["identificador", "minAspectRatio", "maxAspectRatio", "width", "height", "model" ],
	data: function () {
		return {
			image: this.model,
			imageTemporary: null,
			AspectRatio: { 
				minAspectRatio: this.minAspectRatio,
				maxAspectRatio: this.maxAspectRatio
			}
		}
	},
	watch: {
		model : function (val) {
			this.image = val

			if (val == null) {
				this.imageTemporary = null
			}
		}
	},
	methods: {
		imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		change : function ({ coordinates, canvas }) {
			coordinates;
			this.$refs.images.value = null;
			this.image = canvas.toDataURL('image/png');
		},
		onFileChange : function(e) {
			var files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;
			this.createImage(files[0]);
		},
		createImage : function(file) {
			var reader = new FileReader();
			var vm = this;

			reader.onload = (e) => {
				vm.imageTemporary = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		abrirModalEnviarFoto : function() {
			$("#"+this.identificador).modal('show')
		},
		salvarFoto : function () {
			this.$emit("attValor", this.image)
			$("#"+this.identificador).modal('hide')
		},
		fechar : function () {
			$("#"+this.identificador).modal('hide')
		}
	}
}
</script>

<style scoped>

.image_deck {
	border: 2px dashed #6b6b6b;
}

.image_deck img {
	object-fit: contain;
}

</style>