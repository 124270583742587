<template>
	<div class="card mb-2">
		<div class="card-body">
			<div class="row">
				<div class="col-12">
					<h6 class="mb-3">😘 {{ $t("previewMessage.preview.title") }}</h6>
				</div>
				<div class="col-12 p-0">
					<div class="form-group c_form_group mb-0">
						<div class="row">
							<div class="col-12" v-html="textFormat(message.message)"></div>
							<!-- IMAGE -->
							<div class="col-12 mt-2" v-show="message.usarImage">
								<img :src="message.image" class="w-100" @error="imageError" />
								<img v-if="['join', 'private', 'exit', 'endGiveaway'].includes(message.tipo)" :src="dadosUsuario.usuarioFoto == null ? '' : dadosUsuario.usuarioFoto" @error="imageError" class="user_foto_preview" :style="'border:'+message.imageBorderSize+'px solid #fff;'">
								<div class="image_texto">
									<div class="image_titulo" :style="'color:'+ message.imageColorText +'; font-size: '+ message.imageFontSize +'px; font-family: '+ message.imageFont +';'">{{ message.imageTitle }}</div>
									<div class="image_SubTitulo" :style="'color:'+ message.imageColorText +'; font-size: '+ message.imageFontSize +'px; font-family: '+ message.imageFont +';'">{{ message.imageSubtitle }}</div>
								</div>
							</div>
						</div>

						<!-- EMBED -->
						<div class="row m-0" v-show="message.usarEmbed">
							<div class="col-12 px-0 mt-2">
								<div class="class_embed p-3" :style="'border-left: solid 4px '+ message.embedColor +';'">
									<div v-show="message.embedThumbnail != null" class="float-right" style="width: 64px; height: 80px;">
										<img :src="message.embedThumbnail" class="w-100 rounded" @error="imageError" />
									</div>
									<div class="row mx-0 align-items-center">
										<img class="p-0 rounded-circle mr-2" v-show="message.embedAuthorImage != null" @error="imageError" :src="message.embedAuthorImage" style="width: 24px; height: 24px;" />
										<div class="p-0 col font-14 weight-600">{{ message.embedAuthor }}</div>
									</div>
									<div>
										<div class="font-16 mt-2 weight-600" v-html="textFormat(message.embedTitle)"></div>
										<div class="font-14 mt-2" v-html="textFormat(message.embedMessage)"></div>
									</div>
									<div class="mt-2" v-for="(field, index) in message.fields" :key="index">
										<div class="font-14 weight-600" v-html="textFormat(field.title)"></div>
										<div class="font-14" v-html="textFormat(field.value)"></div>
									</div>
									<div class="mt-3">
										<img class="w-100 rounded"  v-show="message.embedImage != null" :src="message.embedImage" @error="imageError" />
									</div>
									<div class="row mt-2">
										<img class="p-0 rounded-circle mr-2" v-show="message.embedFooterImage != null" @error="imageError" :src="message.embedFooterImage" style="width: 20px; height: 20px;" />
										<div class="p-0 col font-12" >{{ message.embedFooter }}</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row mx-0 mt-2" v-if="message.buttons">
							<div v-for="(btn, index) in message.buttons" :key="index" class="btn btn-default cursor-auto m-1">{{ btn.title }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'PreviewMessageDeck',
	props: ["message", "dadosUsuario"],
	methods: {
		textFormat(valor) {
			return String(valor).replace(/\n/g, "<br />")
		},
		imageError: function (e) {
			this.$store.dispatch('imageError', e)
		}
	}
}

</script>